import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import ShinyButton from '../../components/ShinyButton';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';
import { useForm } from 'react-hook-form';

import './style.scss';

const MotionDesignerPage = () => {
    const { getValues, setValue } = useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFromlocalStorage, setdataFromlocalStorage] = useState();
    const [resumeFileName, setResumeFileName] = useState('');

    useEffect(() => {
        retrieveDataFromLocalStorage();
    }, [currentPage]);

    const retrieveDataFromLocalStorage = () => {
        if (localStorage.getItem('resumeData') !== null) {
            const savedValues = JSON.parse(localStorage.getItem('resumeData'));
            setdataFromlocalStorage(savedValues);
            for (let key in savedValues) {
                setValue(key, savedValues[key]);
            }
        }
    };

    const checkUploadedFileSize = file => {
        if (file.size > 52428800) {
            alert('File is too big! Max resume size is 50MB.');
            file.value = '';
            return false;
        }
        return true;
    };

    const setUploadedFileName = (resume, file) => {
        if (checkUploadedFileSize(file[0])) {
            const selectedFile = file[0].name.split('\\');
            localStorage.setItem('resumeData', JSON.stringify(getValues()));

            if (resume === 'resume') {
                setValue('resume', file);
                setResumeFileName(
                    'File Uploaded: ' + selectedFile[selectedFile.length - 1]
                );
            }
        }
    };

    const uploadResume = () => {
        const resumeUpload = document.getElementById('resume');
        resumeUpload.click();
    };

    return (
        <Layout>
            <Seo title="Motion Designer" />
            <div className="motion-designer-page">
                <Container className="purple-gradient-top container-1 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h1>Motion Designer</h1>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-2 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h3>Who we’re looking for</h3>
                            <p className=" wrapLineSib">
                                We’re seeking an idea-driven, self-sufficient,
                                conceptual thinker who uses multimedia designs
                                and creative solutions for GlassHive's brand
                                identity, web, and collateral. We love people
                                who are curious, ask questions, and like to
                                share knowledge and collaborate.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-3 purple-gradient-bottom">
                    <Row>
                        <Col className=" transparent-box max-width-900 margin-auto">
                            <h3>Responsibilities</h3>
                            <li className=" wrapLineSib">
                                Create motion graphics for full videos,
                                animations, and graphic overlays from concept to
                                execution
                            </li>
                            <li>
                                Collaborate with the team on ideas and
                                storyboards and present those concepts to the
                                marketing team and CMO
                            </li>
                            <li>
                                Take a brief to grasp the production team’s
                                needs and specifications
                            </li>
                            <li>
                                Review shooting script and raw material to
                                create a shot decision list based on scenes’
                                value and contribution to continuity
                            </li>
                            <li>
                                Film footage at the office and required
                                locations
                            </li>
                            <li>
                                Interview people and create videos from the
                                footage
                            </li>
                            <li>
                                Set up and tear down cameras, audio recorders,
                                lighting, microphones, and props
                            </li>
                            <li>
                                Trim footage segments and put together the
                                sequence of the video
                            </li>
                            <li>
                                Create assets for social media, such as
                                Facebook, Twitter, Linkedin and YouTube
                            </li>
                            <li>
                                Combine filmed footage, music, dialogues,
                                graphics, and effects to make a final product
                            </li>
                            <li>
                                Collaborate with the marketing team from
                                pre-production to post-production
                            </li>
                            <li>
                                Continuously discover and implement new editing
                                techniques and the industry’s best practices to
                                maximize efficiency in organizing and
                                maintaining file structures
                            </li>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="transparent-box-2 max-width-900 margin-auto">
                            <h3>Required Skills</h3>
                            <li className=" wrapLineSib">
                                At least 2 years of experience in graphic
                                design, motion graphics & video editing
                            </li>
                            <li>
                                The ability to thrive in a fast-paced,
                                deadline-driven environment
                            </li>
                            <li>Strong design and typography skills</li>
                            <li>
                                Solid experience with Adobe Creative Suites
                                (e.g. Premiere, After Effects, Photoshop,
                                Illustrator, and XD )
                            </li>
                            <li>
                                Demonstrable design, video editing, and motion
                                graphics ability with a strong portfolio
                            </li>
                            <li>
                                Thorough knowledge of timing, motion,
                                continuity, color-correcting, etc.
                            </li>
                            <li>Familiarity with compositing</li>
                            <li>Creative mind and storytelling skills</li>
                            <li>
                                Good verbal and written communication skills
                            </li>
                            <li>Great customer service skills</li>
                            <li>Time Management Skills</li>
                            <li>A CAN-DO ATTITUDE</li>
                        </Col>
                    </Row>
                </Container>
                <Container className=" container-4  ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <AppForm
                                formName="careers-form"
                                submitBtnText="Send"
                                className="pink-form careers-form"
                                formCategory="Careers"
                            >
                                <h3 class="centered-text">
                                    Apply now for Motion Designer
                                </h3>
                                <AppInput
                                    fieldName="firstName"
                                    type="text"
                                    labelText="What's your name?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    labelText="What's your email address?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="phone"
                                    type="text"
                                    labelText="What's your phone number?"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="why"
                                    type="text"
                                    labelText="Why do you want to work for GlassHive?"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="goal"
                                    type="text"
                                    labelText="What do you want to achieve with a career at GlassHive?"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="passion"
                                    type="text"
                                    labelText="What are you passionate about? (Doesn't have to be business related)"
                                    requiredField
                                />

                                <AppInput
                                    fieldName="movie"
                                    type="text"
                                    labelText="What's your favorite movie?"
                                />
                                <div className="file centered-text">
                                    <label
                                        htmlFor="resume"
                                        className="custom-file-upload"
                                    >
                                        <input
                                            type="file"
                                            name="resume"
                                            id="resume"
                                            className="file-upload"
                                            required
                                            onChange={e => {
                                                setUploadedFileName(
                                                    'resume',
                                                    e.target.files
                                                );
                                            }}
                                        />
                                        <p className="choose-a-file">
                                            Drop video resume/cover letter here
                                            or
                                            <ShinyButton
                                                className="btn white create-free-account-button"
                                                onClick={() => uploadResume()}
                                            >
                                                Select Files
                                            </ShinyButton>
                                        </p>
                                        <p id="fileUploadedName">
                                            {resumeFileName}
                                        </p>
                                    </label>
                                </div>
                                <AppInput
                                    fieldName="video"
                                    type="text"
                                    labelText="Video or Portfolio URL"
                                />
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default MotionDesignerPage;
